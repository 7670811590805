@import "src/assets/css/_variables";

.SurveyHourItem {
  &.hasKea {
    background-color: rgba($secondary, 0.3);
  }
  &.activity-X {
    background-color: $light;

    .field-activity {
      font-style: italic;
    }
  }
}
