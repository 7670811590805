@import "src/assets/css/_variables";

.Banner {
  background: $brown-faded;
  background-repeat: no-repeat;
  background-position: center 15%;
  background-size: cover;
  max-width: 1920px; // Actual image maximum size
  margin: auto;

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  &.size-home {
    // Add extra top padding to approximate for header
    padding-top: $spacer * 12;
    padding-bottom: $spacer * 10;

    @include media-breakpoint-down(sm) {
      padding-top: $spacer * 8;
      padding-bottom: $spacer * 7;
    }

    @include media-breakpoint-up(md) {
      h1 {
        font-size: $display4-size;
      }
    }
  }

  &.size-large {
    padding-top: $spacer * 10;
    padding-bottom: $spacer * 10;
    @include media-breakpoint-down(sm) {
      padding-top: $spacer * 7;
      padding-bottom: $spacer * 7;
    }
  }
  &.size-medium {
    padding-top: $spacer * 6;
    padding-bottom: $spacer * 6;
  }
  &.size-small {
    padding-top: $spacer * 3;
    padding-bottom: $spacer * 3;
  }

  .banner-title {
    padding: $spacer;
    background: rgba($dark, 0.85);
    display: inline-block;
    color: $white;
  }
}
