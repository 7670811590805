@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

// Custom variables go here

$font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family: "Zilla Slab", serif;
$headings-font-weight: 500;

$orange-dark: #df5206;
$green-dark: #6a7029;
$brown-dull: #dfd4ba;
$brown-faded: #e6dfcc;

$primary: $orange-dark;
$secondary: $green-dark;
$light: #e9ecef;
$white: #fff;
$black: #000;

$card-spacer-x: 0.9rem;
$card-spacer-y: 0.9rem;

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$navbar-dark-color: rgba($white, 0.75);
$navbar-dark-hover-color: rgba($white, 0.6);

$navbar-light-color: rgba($black, 0.75);
$navbar-light-hover-color: rgba($black, 0.6);

$navbar-nav-link-padding-x: 1rem;

@import "~bootstrap/scss/variables";
